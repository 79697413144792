import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid"; 
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../assets/logo_login.png";


const Copyright = () => {
	return (
		<Typography variant="body2" color="primary" align="center">
			{"Copyright "}
 			<Link color="primary" href="#">
			 - DCT SISTEMAS -
 			</Link>{" "}
 			{new Date().getFullYear()}
 			{"."}
 		</Typography>
 	);
 };

 const useStyles = makeStyles(theme => ({
	root: {
	  width: "100vw",
	  height: "100vh",
	  backgroundImage: "url(https://cdn.pixabay.com/photo/2017/09/03/06/12/abstract-2709402_640.png)",
	  backgroundRepeat: "no-repeat",
	  backgroundSize: "100% 100%",
	  backgroundPosition: "center",
	  display: "flex",
	  flexDirection: "column",
	  alignItems: "center",
	  justifyContent: "center",
	  textAlign: "center",
	  // Ajuste para desktop usando breakpoint
	  [theme.breakpoints.up("md")]: {
		width: "133.345vw", // Ajuste para aumentar a largura no desktop
		height: "133.345vh", // Ajuste para aumentar a altura no desktop
		backgroundSize: "100% 100%", // Certifica-se de que o fundo cubra 100% no desktop
	  },
	},
	paper: {
	  backgroundColor: theme.palette.login, // DARK MODE PLW DESIGN//
	  display: "flex",
	  background: "#ffffffe0",
	  flexDirection: "column",
	  alignItems: "center",
	  padding: "55px 30px",
	  borderRadius: "12.5px",
	},
	avatar: {
	  margin: theme.spacing(1),
	  backgroundColor: theme.palette.secondary.main,
	},
	form: {
	  width: "100%", // Fix IE 11 issue.
	  marginTop: theme.spacing(1),
	},
	submit: {
	  margin: theme.spacing(3, 0, 2),
	},
	powered: {
	  color: "white",
	},
  }));
  
const Login = () => {
	const classes = useStyles();

	const [user, setUser] = useState({ email: "", password: "" });

	const { handleLogin } = useContext(AuthContext);

	const handleChangeInput = e => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const handlSubmit = e => {
		e.preventDefault();
		handleLogin(user);
	};

	return (
		<div className={classes.root}>
		<Container component="main" maxWidth="xs">
			<CssBaseline/>
			<div className={classes.paper}>
				<div>
					<img style={{ margin: "0 auto", width: "100%" }} src={logo} alt="Whats" />
				</div>
				{/*<Typography component="h1" variant="h5">
					{i18n.t("login.title")}
				</Typography>*/}
				<form className={classes.form} noValidate onSubmit={handlSubmit}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label={i18n.t("login.form.email")}
						name="email"
						value={user.email}
						onChange={handleChangeInput}
						autoComplete="email"
						autoFocus
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label={i18n.t("login.form.password")}
						type="password"
						id="password"
						value={user.password}
						onChange={handleChangeInput}
						autoComplete="current-password"
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="success"
						className={classes.submit}
					>
						{i18n.t("login.buttons.submit")}
					</Button>
					{ <Grid container>
						<Grid item>
						</Grid>
						
					</Grid> }
					<Box mt={8} >{ <Copyright /> }</Box>
				</form>
			
			</div>
		
		</Container>
		</div>
	);
};

export default Login;
